import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FieldBlock, DatePicker } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import Editor from 'cccisd-wysiwyg';
import Toggle from 'js/components/Toggle';
import { Link } from 'react-router-dom';
import Tooltip from 'cccisd-tooltip';
import IconQuestion from 'cccisd-icons/question4';
import style from './style.css';

const fields = [
    'messages[].handle',
    'messages[].title',
    'messages[].message',
    'dates.memberSurveyStartDate',
    'dates.memberSurveyEndDate',
    'dates.staffSurveyStartDate',
    'dates.staffSurveyEndDate',
    'dates.memberSurveyPaperDueDate',
    'dates.adminCenterStartDate',
    'dates.adminCenterEndDate',
    'dates.allowPaperDueDate',
    'dates.secondarySurveyStartDate',
    'dates.secondarySurveyEndDate',
    'staffSurveyOn',
    'memberSurveyOn',
    'adminCenterOn',
    'secondarySurveyOn',
];

class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
    };

    render() {
        const {
            messages,
            dates: {
                memberSurveyStartDate,
                memberSurveyEndDate,
                staffSurveyStartDate,
                staffSurveyEndDate,
                memberSurveyPaperDueDate,
                adminCenterStartDate,
                adminCenterEndDate,
                allowPaperDueDate,
                secondarySurveyStartDate,
                secondarySurveyEndDate,
            },
            staffSurveyOn,
            memberSurveyOn,
            adminCenterOn,
            secondarySurveyOn,
        } = this.props.fields;

        return (
            <form onSubmit={this.props.handleSubmit}>
                <h3>Messages</h3>
                {messages
                    .filter(item => item.handle.value !== 'needHelp')
                    .map(item => (
                        <div key={item.handle.value} data-message-field={item.handle.value}>
                            <FieldBlock field={item.message} label={item.title.value}>
                                <Editor content={item.message.value} onChange={item.message.onChange} />
                            </FieldBlock>
                        </div>
                    ))}

                <h3>Help Center</h3>
                <div>
                    <Link to="/helpCenterAdmin">Edit Help Center</Link>
                </div>

                <h3>Control Open Areas of the Site</h3>
                <div className={style.toggle}>
                    <Toggle {...staffSurveyOn} label="Allow staff survey" />
                </div>
                <div className={style.toggle}>
                    <Toggle {...memberSurveyOn} label="Allow member survey" />
                </div>
                <div className={style.toggle}>
                    <Toggle {...adminCenterOn} label="Allow admin center" />
                </div>
                <div className={style.toggle}>
                    <Toggle {...secondarySurveyOn} label="Allow secondary survey" />
                </div>

                <h3>Dates for Display Purposes</h3>
                <div className={style.grid}>
                    <FieldBlock
                        field={memberSurveyStartDate}
                        label={
                            <div>
                                Member survey
                                <br />
                                start date{' '}
                                <Tooltip title="First day members can take the NYOI survey">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={memberSurveyStartDate} />
                    </FieldBlock>
                    <FieldBlock
                        field={memberSurveyEndDate}
                        label={
                            <div>
                                Member survey
                                <br />
                                end date{' '}
                                <Tooltip title="Last day members can take the NYOI survey">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={memberSurveyEndDate} />
                    </FieldBlock>
                    <FieldBlock
                        field={staffSurveyStartDate}
                        label={
                            <div>
                                Staff survey
                                <br />
                                start date{' '}
                                <Tooltip title="First day staff members can take the staff survey">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={staffSurveyStartDate} />
                    </FieldBlock>
                    <FieldBlock
                        field={staffSurveyEndDate}
                        label={
                            <div>
                                Staff survey
                                <br />
                                end date{' '}
                                <Tooltip title="Last day staff members can take the staff survey">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={staffSurveyEndDate} />
                    </FieldBlock>
                    <FieldBlock
                        field={memberSurveyPaperDueDate}
                        label={
                            <div>
                                Member survey
                                <br />
                                paper due date{' '}
                                <Tooltip title="Last day for Clubs to mail back paper surveys">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={memberSurveyPaperDueDate} />
                    </FieldBlock>
                    <FieldBlock
                        field={adminCenterStartDate}
                        label={
                            <div>
                                Admin center
                                <br />
                                start date{' '}
                                <Tooltip title="First day org admins can register Clubs">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={adminCenterStartDate} />
                    </FieldBlock>
                    <FieldBlock
                        field={adminCenterEndDate}
                        label={
                            <div>
                                Admin center
                                <br />
                                close date{' '}
                                <Tooltip title="Last day org admins can access admin center">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={adminCenterEndDate} />
                    </FieldBlock>
                    <FieldBlock
                        field={allowPaperDueDate}
                        label={
                            <div>
                                Allow paper
                                <br />
                                end date{' '}
                                <Tooltip title="Last day paper survey administration can be selected for Clubs">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={allowPaperDueDate} />
                    </FieldBlock>
                    <FieldBlock
                        field={secondarySurveyStartDate}
                        label={
                            <div>
                                Secondary member
                                <br />
                                start date{' '}
                                <Tooltip title="First day for members to take the NYOI survey during second survey administration period">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={secondarySurveyStartDate} />
                    </FieldBlock>
                    <FieldBlock
                        field={secondarySurveyEndDate}
                        label={
                            <div>
                                Secondary member
                                <br />
                                end date{' '}
                                <Tooltip title="Last day for members to take the NYOI survey during second survey administration period">
                                    <IconQuestion />
                                </Tooltip>
                            </div>
                        }
                    >
                        <DatePicker field={secondarySurveyEndDate} />
                    </FieldBlock>
                </div>

                <ClickButton
                    title="Submit"
                    isLoading={this.props.submitting}
                    onClick={this.props.handleSubmit}
                    className="btn btn-primary"
                />
            </form>
        );
    }
}

export default reduxForm({ form: 'MessagesForm', fields })(Form);
