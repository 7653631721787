import React from 'react';
import PropTypes from 'prop-types';
import { updateParams } from 'js/reducers/params.js';
import { connect } from 'react-redux';
import Form from './form.js';
import notification from 'cccisd-notification';

class Params extends React.Component {
    static propTypes = {
        params: PropTypes.object,
        updateParams: PropTypes.func,
    };

    onSubmit = async values => {
        await this.props.updateParams(values);
        notification('The params have been updated');
    };

    render() {
        return (
            <div>
                <h2>Site Content</h2>
                <Form initialValues={this.props.params} onSubmit={this.onSubmit} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    params: state.app.params.data,
});

export default connect(
    mapStateToProps,
    { updateParams }
)(Params);
